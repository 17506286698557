// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';


const firebaseConfig = {
  apiKey: "AIzaSyCZ_Y-8k3c0z-EUfTVBht2XnEdMwympHh8",
  authDomain: "follio-77848.firebaseapp.com",
  projectId: "follio-77848",
  storageBucket: "follio-77848.appspot.com",
  messagingSenderId: "868412861707",
  appId: "1:868412861707:web:b9ef7e6b109e48fe5bc60e",
  measurementId: "G-L4Y67BXJ51"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);