import React from 'react';
import './styles.scss';
import { Link } from '@chakra-ui/react';
import Logo from '../Logo/Logo';
import moment from 'moment';

const Footer = () => {
  return (
    <div className="footer_container">
      {/* <div className='row'>
        <Link>Terms of use</Link>
        <Link>Policy Policy</Link>
      </div> */}
      <div className='site'>
        <p>&copy;&nbsp;{moment().format('YYYY')}</p>
        <Logo height={"15px"} fontSize={"14px"} />
      </div>
    </div>
  )
}

export default Footer