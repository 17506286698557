import React from 'react';
import Header from '../Header/Header';
import "./styles.scss";
import Portfolio from '../Portfolio/Portfolio';

const Home = () => {

  return (
    <div className='home_wrapper'>
      <Header />
      <Portfolio />
    </div>
  )
}

export default Home