import React from 'react';
import { Animate } from 'react-simple-animate';

const ElegantText = ({ children }) => {
  return (
    <Animate
      play // Starts the animation on load
      duration={1} // Animation duration of 1 second
      start={{ opacity: 0, transform: 'translateY(20px)' }}
      end={{ opacity: 1, transform: 'translateY(0)' }}
      easeType="ease-in-out" // Smooth easing
    >
      {children}
    </Animate>
  )
}

export default ElegantText;