import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import { useSelector } from "react-redux";
import PrivateRoute from "./routes/PrivateRoute";
import PublicRoute from "./routes/PublicRoute";
import Landing from "./components/Landing/Landing";
import Home from "./components/Home/Home";
import { useEffect, useState } from "react";
import MainLanding from "./components/MainLanding/MainLanding";


function App() {

  const user = useSelector(state => state.user);
  const [routesArr, setroutesArr] = useState([]);

  useEffect(() => {
    setroutesArr([
      { path: "/", element: user.isLoggedIn ? <Home /> : <MainLanding />, isAuthRequired: user.isLoggedIn },
    ])
  }, [user.isLoggedIn])


  return (
    <BrowserRouter>
      <Routes>
        {/* Public routes */}
        {routesArr.map((item) => {
          if (item.isAuthRequired) {
            return (<Route
              path={item.path}
              key={item.path}
              element={
                <PrivateRoute>
                  {item.element}
                </PrivateRoute>
              }
            />)
          } else {
            return (
              <Route path={item.path}
                key={item.path}
                element={
                  <PublicRoute>
                    {item.element}
                  </PublicRoute>}
              />)
          }
        })}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
