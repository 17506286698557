import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoggedIn: false,
  username: null,
  email: null,
  fullname: null,
  role: null
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state, action) => {
      state.username = action.payload.username
      state.email = action.payload.email
      state.fullname = action.payload.fullname
      state.role = action.payload.role
      state.isLoggedIn = true
    },
    logout: (state) => {
      state.username = null
      state.email = null
      state.fullname = null
      state.role = null
      state.isLoggedIn = false
    }
  },
})

// Action creators are generated for each case reducer function
export const { login, logout } = userSlice.actions

export default userSlice.reducer;