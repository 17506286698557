import React, { useRef, useEffect } from 'react';
import "./Card.scss";

const Card = ({ children }) => {
  const cardRef = useRef(null);

  useEffect(() => {
    const card = cardRef.current;

    const handleMouseMove = (e) => {
      const rect = card.getBoundingClientRect();
      const x = e.clientX - rect.left; // x position within the element.
      const y = e.clientY - rect.top;  // y position within the element.

      const centerX = rect.width / 2;
      const centerY = rect.height / 2;

      const rotateX = (y - centerY) / centerY * 10;
      const rotateY = (x - centerX) / centerX * -10;

      card.style.transform = `rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
      
      // Change gradient lighting
      const gradientX = (x / rect.width) * 100;
      const gradientY = (y / rect.height) * 100;

      card.style.background = `radial-gradient(circle at ${gradientX}% ${gradientY}%, rgb(67, 54, 74), rgb(27, 23, 36))`;
    };

    const handleMouseLeave = () => {
      card.style.transform = 'rotateX(0) rotateY(0)';
      card.style.background = 'linear-gradient(110.6deg, rgb(156, 116, 129) -18.3%, rgb(67, 54, 74) 16.4%, rgb(47, 48, 67) 68.2%, rgb(27, 23, 36) 99.1%)';
    };

    card.addEventListener('mousemove', handleMouseMove);
    card.addEventListener('mouseleave', handleMouseLeave);

    // Cleanup event listeners on unmount
    return () => {
      card.removeEventListener('mousemove', handleMouseMove);
      card.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);

  return (
    <div className='card' ref={cardRef}>
      {children}
    </div>
  );
}

export default Card;
