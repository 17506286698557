import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    black: '#000000',
    white: '#FFFFFF',
    // Add additional color customizations if needed
  },
  components: {
    Button: {
      baseStyle: {
        borderRadius: 'md',
        fontWeight: '400',
        transition: 'all 0.3s ease', // Smooth transition effect
      },
      sizes: {
        sm: {
          fontSize: 'sm',
          px: '4',
          py: '2',
        },
        md: {
          fontSize: 'md',
          px: '6',
          py: '3',
        },
        lg: {
          fontSize: 'lg',
          px: '8',
          py: '4',
        },
      },
      variants: {
        solid: (props) => ({
          bg: props.colorMode === 'dark' ? 'white' : 'black',
          color: props.colorMode === 'dark' ? 'black' : 'white',
          _hover: {
            color: props.colorMode === 'dark' ? 'black' : 'white',
            transform: 'scale(1.05)', // Slightly scale up the button
            outline: 'none',
            boxShadow: 'none', // Add shadow on hover
          },
          _active: {
            transform: 'scale(0.98)', // Slightly scale down when active
            boxShadow: 'none', // Adjust shadow on active
            outline: 'none'
          },
        }),
      },
    },
  }
  // Add any other theme customizations here
});

export default theme;
