import React from 'react'
import Logo from '../Logo/Logo'
import { Button, Menu, MenuButton, MenuItem, MenuList, useToast } from '@chakra-ui/react'
import "./styles.scss";
import { FiSettings } from "react-icons/fi";
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/slices/userSlice';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase.config';
import { RiLogoutBoxLine } from "react-icons/ri";
import { openLink, scrollToSection } from '../../helper/random.helper';

const Header = ({ type }) => {
  const dispatch = useDispatch();

  const toast = useToast();

  const handleSignOut = () => {
    signOut(auth).then(() => {
      dispatch(logout());
    }).catch((error) => {
      toast({
        title: `Something went wrong! Please try again.`,
        status: 'error',
        isClosable: true,
      })
    });
  }

  return (
    <div className="header_wrapper">
      <Logo height={"35px"} fontSize="25px" />
      {type === "private" ?
        <Menu>
          <MenuButton>
            <FiSettings size={18} />
          </MenuButton>
          <MenuList>
            <MenuItem icon={<RiLogoutBoxLine size={18} />} onClick={handleSignOut}>Sign out</MenuItem>
          </MenuList>
        </Menu> :
        <>
          <div className="other_menu">
            <p className='hidable' onClick={() => scrollToSection('about')}>About</p>
            <p className='hidable' onClick={() => scrollToSection('objectives')}>Objectives</p>
            {/* <p>Login</p>
            <Button colorScheme='brand' size='md'>
              Sign up
            </Button> */}
            <Button colorScheme='brand' size='md' onClick={() => openLink("https://x.com/RehumanWork")}>
              Join the Converstion
            </Button>
          </div>

        </>
      }

    </div >
  )
}

export default Header