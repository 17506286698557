import { Image } from '@chakra-ui/react'
import React from 'react';
import rehuman_logo from '../../assets/rehuman.png';
import './styles.scss';

const Logo = ({ height = "45px", fontSize = "34px" }) => {
  return (
    <div className='landing_logo'>
      {/* <Image src={rehuman_logo} height={height} alt={"Rehuman logo"} draggable={false} /> */}
      <p className='logo_name' style={{ fontSize: fontSize }} ><span>Re</span>human</p>
    </div >
  )
}

export default Logo