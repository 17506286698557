import React, { useState } from 'react'
import Header from '../Header/Header';
import "./styles.scss";
import {
  Button, Image, Input, useToast
} from '@chakra-ui/react';
import { AIHumans } from '../../assets/AIHumans';
import { MdArrowOutward } from "react-icons/md";
import Card from '../atoms/Card';
import Footer from '../Footer/Footer';
import { useForm } from 'react-hook-form';
import { GoNorthStar } from 'react-icons/go';
import { FaXTwitter } from "react-icons/fa6";
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase.config';
import { scrollToSection } from '../../helper/random.helper';

const MainLanding = () => {
  const [FormSubmitMessage, setFormSubmitMessage] = useState('');
  const {
    register,
    values,
    setValues,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();

  const onSubmit = async (value) => {
    try {
      // Add the email to the "subscribe" collection
      const emailDocRef = doc(db, 'subscribe', value.email);
      await setDoc(emailDocRef, { email: value.email });

      // Clear the form and show success message
      reset();
      setFormSubmitMessage('We have added you to our list!')
    } catch (error) {
      setFormSubmitMessage('Something went wrong! Please try again.')
    }
  };

  return (
    <div className="main_wrapper">
      <Header type="public" />
      <div className="hero">
        <h1 className="hero_text">Work defines purpose. We aim to redefine work in an era of technological change.</h1>
        <div className="hero_para">Empowering the Future of Work by Bridging Innovation and Purpose. Together, we help people navigate and thrive in a rapidly evolving world.</div>
        <div className='btn_with_arrow'>
          <Button colorScheme='brand' size='lg' onClick={() => scrollToSection('subs')}>
            Get Started&nbsp;<MdArrowOutward size={"25px"} id="arrow" />
          </Button>
        </div>
        <p className="bottom_text"></p>
      </div>
      <div className="conversation_wrapper">
        <p className="small_heading">Conversation around AI</p>
        <div className="card_wrapper">
          <Card>
            <div className='quote'>"The society that we build up which is AI assisted humans, using these tools to build up this society and the knowledge."</div>
            <div className='quote_by'>Sam Altman, CEO of OpenAI</div>
          </Card>
          <Card>
            <div className='quote'>"I imagine a world in which AI is going to make us work more productively, live longer, and have cleaner energy."</div>
            <div className='quote_by'>Fei-Fei Li, Professor of Computer Science at Stanford University</div>
          </Card>
          <Card>
            <div className='quote'>"Today, nobody has any idea what to teach young people that will still be relevant in 20 years."</div>
            <div className='quote_by'>Prof. Yuval Noah Harari, historian, philosopher, and best-selling author of 'Sapiens' and 'Homo Deus'</div>
          </Card>
          <Card>
            <div className='quote'>"We will come to see them (AI) as digital companions, new partners in the journey of all our lives."</div>
            <div className='quote_by'>Mustafa Suleyman, CEO of Microsoft AI, co-founder of DeepMind and Inflection AI, and best-selling author of 'The Coming Wave'</div>
          </Card>
          <Card>
            <div className='quote'>"What will happen is not that AI will replace people, but I think people that use AI will replace people that don't."</div>
            <div className='quote_by'>Andrew Ng, Founder of DeepLearning.AI, General Partner at AI Fund, Co-Founder of Coursera, and Adjunct Professor at Stanford University</div>
          </Card>
        </div>
      </div>
      <div className="section_template" id="about">
        <div className="bundle_title">
          <GoNorthStar size={"20px"} id="star" />
          <p className="title">About</p>
        </div>
        <div className="content">Dedicated to transforming how we perceive and engage with work. As technology continues to advance, our mission is to ensure that work remains a source of purpose and fulfillment. We believe in a future where innovation and human potential coalesce to create meaningful opportunities for all.</div>
      </div>
      <div className='big_text_container'>
        <AIHumans />
      </div>
      <div className="section_template" id="objectives">
        <div className="bundle_title">
          <GoNorthStar size={"20px"} id="star" />
          <p className="title">Objectives</p>
        </div>
        <div className="content">
          <div className="numbered_card">
            <p className="title">Identify Skill Gaps</p>
            <p className="detail">Help individuals pinpoint areas for improvement to stay competitive in an evolving job market.</p>
          </div>
          <div className="numbered_card">
            <p className="title">Personalized Roadmaps & Upskilling Opportunities</p>
            <p className="detail">Tailored learning paths and adaptive content to enhance skills, empowering users to advance in their careers effectively.</p>
          </div>
          <div className="numbered_card">
            <p className="title">Empower Autonomy</p>
            <p className="detail"> Enable users to find flexible gigs and project-based roles while maintaining their independence.</p>
          </div>
        </div>
      </div>
      <div className="section_template">
        <div className="bundle_title">
          <GoNorthStar size={"20px"} id="star" />
          <p className="title">Big Question of 21st Century</p>
        </div>
        <div className="content_center">
          The question of whether AI will ever achieve or surpass human-level intelligence remains a major debate, with experts in the AI community divided on both the timeline and the feasibility. Each perspective is backed by leading researchers and thinkers in the field. What is undeniably clear, however, is the significant disruption already in progress and expected to continue for decades. This upheaval is reshaping the employment landscape and raises critical questions about the future of the workforce and how we will address these transformative changes.
        </div>
        <div className='join'>
          <p>
            Join the conversation
          </p>
          <a href="https://x.com/RehumanWork" target={'_blank'}><FaXTwitter size={"30px"} className="social" /></a>
        </div>
      </div>
      <p className='mail'>
        contact <a href="mailto:hello@rehuman.work">hello@rehuman.work</a>
      </p>

      <div className='subscribe' id="subs">
        <p className='title'>
          Become a part of our journey
        </p>
        <p className="detail">
          Stay informed about our progress and be the first to know about our upcoming launch
        </p>
        <Input
          type="email"
          className='email'
          placeholder='Enter your email'
          {...register('email', {
            required: 'Email is required',
            pattern: {
              value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: 'Please enter a valid email address'
            }
          })}
        />
        <Button colorScheme='brand' size='md' onClick={handleSubmit(onSubmit)} mt={2}>
          Submit
        </Button>
        {errors.email && <p className='error'>{errors.email.message}</p>}
        {FormSubmitMessage && <p className="submit_msg_1">{FormSubmitMessage}</p>}
      </div>
      <Footer />
    </div>
  )
}

export default MainLanding;