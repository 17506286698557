import React, { useEffect, useState } from 'react';
import { HiArrowLongRight } from "react-icons/hi2";
import { Animate } from 'react-simple-animate';
import ElegantText from '../../atoms/ElegantText';
import { GoNorthStar } from "react-icons/go";
import { useSelector } from 'react-redux';
import { Link } from '@chakra-ui/react';

const StepTwo = ({ setStep }) => {
  const user = useSelector(state => state.user);


  return (
    <div className="message">
      <GoNorthStar size={"60px"} id="star" />
      <ElegantText>
        <p className='message_highlight'>Hello, {user?.fullname?.split(' ')[0]}!</p>
      </ElegantText>
      <ElegantText>
        <p className='message_para'>Please <Link className='link'> upload your resume</Link> or <Link className='link'>connect your LinkedIn</Link> to help us get to know you better.</p>
      </ElegantText>
    </div>
  )
}

export default StepTwo;